import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import ulog from "ulog";

// setup log object
const log = ulog("topdo");
log.level = log.DEBUG;

class AppHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formattedTime: this.formattedTime(),
        };
    }
    formattedTime() {
        return moment().format("ddd YYYY-MM-DD HH:mm:ss Z");
    }
    componentDidMount() {
        this.timerID = setInterval(() => this.tick(), 1000);
    }

    tick() {
        this.setState({ formattedTime: this.formattedTime() });
    }

    render() {
        return (
            <>
                <nav>
                    <ul>
                        <li>
                            <Link to="/">topdos</Link>
                        </li>
                        <li>
                            <Link to="/auth">auth</Link>
                        </li>
                        <li>
                            <span>{this.state.formattedTime}</span>
                        </li>
                    </ul>
                </nav>
                <h1>TopDo</h1>
            </>
        );
    }
}

export default AppHeader;
