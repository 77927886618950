import React from "react";
import ulog from "ulog";

import AppHeader from "./AppHeader";

// setup log object
const log = ulog("topdo");
log.level = log.DEBUG;

class AuthPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            readAuthKey: null,
        };
        this.onChangeRead = this.onChangeRead.bind(this);
        this.onChangeWrite = this.onChangeWrite.bind(this);
    }

    onChangeRead(event) {
        localStorage.setItem("readAuthKey", event.target.value);
        this.setState({
            readAuthKey: event.target.value,
        });
    }

    onChangeWrite(event) {
        localStorage.setItem("writeAuthKey", event.target.value);
        this.setState({
            writeAuthKey: event.target.value,
        });
    }

    componentDidMount() {
        var readAuthKey = localStorage.getItem("readAuthKey");
        var writeAuthKey = localStorage.getItem("writeAuthKey");
        this.setState({
            readAuthKey: readAuthKey,
            writeAuthKey: writeAuthKey,
        });
    }

    render() {
        return (
            <>
                <AppHeader />
                <div className="page">
                    <h2>Auth</h2>
                    <table>
                        <tr>
                            <td className="breathe">Read Auth Token:</td>
                            <td className="breathe">
                                <input
                                    type="password"
                                    onChange={this.onChangeRead}
                                    value={this.state.readAuthKey}
                                    style={{ width: "25em" }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="breathe">Write Auth Token: </td>
                            <td className="breathe">
                                <input
                                    type="password"
                                    onChange={this.onChangeWrite}
                                    value={this.state.writeAuthKey}
                                    style={{ width: "25em" }}
                                />
                            </td>
                        </tr>
                    </table>
                </div>
            </>
        );
    }
}

export default AuthPage;
