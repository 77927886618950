import React from "react";
import { Link } from "react-router-dom";
import ulog from "ulog";

import AppHeader from "./AppHeader";
import config from "./config";

// setup log object
const log = ulog("topdo");
log.level = log.DEBUG;

class IndexPage extends React.Component {
    render() {
        return (
            <>
                <AppHeader />
                <h2>Index Page</h2>
                <TopDoList />
            </>
        );
    }
}

class TopDoList extends React.Component {
    constructor(props) {
        super(props);
        this.failed = false;
        this.readAuthKey = null;
        this.writeAuthKey = null;
        this.fetchStatusText = null;
        this.fetchStatus = null;
        this.state = {
            error: null,
            todos: [],
        };
        this.deleteHandler = this.deleteHandler.bind(this);
        this.completeHandler = this.completeHandler.bind(this);
        this.newHandler = this.newHandler.bind(this);
    }

    deleteHandler(uri) {
        const url = config.APIURL + uri;
        fetch(url, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ _auth: this.writeAuthKey }),
        })
            .then(res => {
                this.updateList();
            })
            .catch(err => {});
        this.updateList();
    }

    completeHandler(uri) {
        const url = config.APIURL + uri + "/complete";
        fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ _auth: this.writeAuthKey }),
        })
            .then(res => {
                this.updateList();
            })
            .catch(err => {});
        this.updateList();
    }

    newHandler(args) {
        // trailing slash is important or flask will redirect
        // remember: trailing slash for plural
        const url = config.APIURL + "/api/topdo/";
        const bodystruct = {
            ...args,
            _auth: this.writeAuthKey,
        };

        const body = JSON.stringify(bodystruct);

        fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: body,
        })
            .then(res => {
                this.updateList();
            })
            .catch(err => {});
        this.updateList();
    }

    componentDidMount() {
        this.readStorage();
        this.updateList();
    }

    readStorage() {
        this.readAuthKey = localStorage.getItem("readAuthKey");
        this.writeAuthKey = localStorage.getItem("writeAuthKey");
    }

    updateList() {
        const url = config.APIURL + "/api/topdo/all";
        fetch(
            url +
                "?" +
                new URLSearchParams({
                    auth: this.readAuthKey,
                })
        )
            .then(resp => {
                this.fetchStatusText = resp.statusText;
                this.fetchStatus = resp.status;
                return resp.json();
            })
            .then(resp => {
                if (resp.message) {
                    this.setState({ errorMessage: resp.message });
                }
                if (!!resp) {
                    this.setState({ todos: resp });
                }
            });
    }

    render() {
        if (!this.readAuthKey || !this.writeAuthKey) {
            return (
                <span>
                    Please <Link to="/auth">supply valid credentials</Link>.
                </span>
            );
        }

        if (this.state.errorMessage) {
            return (
                <span>
                    Please <Link to="/auth">supply valid credentials</Link>{" "}
                    (error: {this.state.errorMessage}).
                </span>
            );
        }

        return (
            <div className="topdolist">
                <AddNewForm newHandler={this.newHandler} />
                <ul>
                    {this.state.todos.map((todo, index) => {
                        return (
                            <TopDoListItem
                                todo={todo}
                                key={todo.id}
                                big={index === 0}
                                deleteHandler={this.deleteHandler}
                                completeHandler={this.completeHandler}
                            />
                        );
                    })}
                </ul>
            </div>
        );
    }
}

class TopDoListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.todo.id,
            todo: props.todo,
            completed: !!this.props.todo.completed,
            topdo: this.props.index === 0,
        };
    }

    deleteHandler() {
        this.props.deleteHandler(this.state.todo.uri);
    }

    completeHandler() {
        this.setState({
            completed: true,
        });
        this.props.completeHandler(this.state.todo.uri);
    }
    render() {
        const big = this.props.big ? "itemTOPDO" : "";
        const completedClass = this.state.completed ? "itemCOMPLETED" : "";
        const titleTruncated =
            this.state.todo.title.length > 30
                ? this.state.todo.title.substring(0, 30).trim() + "..."
                : this.state.todo.title;
        return (
            <li className={`todoItem ${big} ${completedClass}`}>
                [<button onClick={this.deleteHandler.bind(this)}>del</button>
                ]&nbsp;[
                <button
                    disabled={this.state.completed}
                    onClick={this.completeHandler.bind(this)}
                >
                    complete
                </button>
                ]&nbsp;
                <b className="todoText">{this.state.todo.priority}</b>:{" "}
                <span className="todoText">{titleTruncated}</span>
            </li>
        );
    }
}

const DEFAULT_PRIORITY = 500;
const DEFAULT_TITLE = "";

class AddNewForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            priority: DEFAULT_PRIORITY,
            title: DEFAULT_TITLE,
        };
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handlePriorityChange = this.handlePriorityChange.bind(this);
        this.newHandler = this.newHandler.bind(this);
    }

    newHandler(e) {
        const newitem = {
            priority: this.state.priority,
            title: this.state.title,
        };
        this.props.newHandler(newitem);
        this.setState({
            priority: DEFAULT_PRIORITY,
            title: DEFAULT_TITLE,
        });
        e.preventDefault();
    }

    handleTitleChange(e) {
        this.setState({ title: e.target.value });
    }

    handlePriorityChange(e) {
        this.setState({ priority: e.target.value });
    }

    render() {
        return (
            <form onSubmit={this.newHandler}>
                <input
                    type="text"
                    style={{
                        width: "20em",
                        fontSize: "20pt",
                        marginRight: "3em",
                    }}
                    placeholder="new item..."
                    value={this.state.title}
                    onChange={this.handleTitleChange}
                />
                <select
                    name="priority"
                    id="priority"
                    value={this.state.priority}
                    onChange={this.handlePriorityChange}
                >
                    <option value="1">1: Lowest</option>
                    <option value="100">100: Low</option>
                    <option value="250">250: Slightly Below Default</option>
                    <option value="500">500: Default</option>
                    <option value="750">750: Higher</option>
                    <option value="1000">1000: High</option>
                    <option value="9001">9001: Over 9000</option>
                </select>
                <input type="submit" />
            </form>
        );
    }
}

export default IndexPage;
