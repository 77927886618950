import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ulog from "ulog";

import IndexPage from "./IndexPage";
import AuthPage from "./AuthPage";
import "./TopDo.css";

// setup log object
const log = ulog("topdo");
log.level = log.DEBUG;

class TopDo extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/">
                        <IndexPage />
                    </Route>
                    <Route path="/auth">
                        <AuthPage />
                    </Route>
                </Switch>
            </Router>
        );
    }
}

export default TopDo;
