// app config
const prodConfig = {
    APIURL: "",
};

const devConfig = {
    APIURL: "https://topdo.sneak.cloud",
};

const config = process.env.NODE_ENV === "development" ? devConfig : prodConfig;
export default config;
